import React from "react";
import styles from "./Header.module.scss";
import Menu from "../Menu";
import { ReactComponent as LogoSvg } from "../../assets/images/logo.svg";

const Header = (props) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.logoSign} title="Avkash Chevli">
        <LogoSvg className={styles.logoSvg} />
      </h2>
      <Menu history={props.history} />
    </div>
  );
};

export default Header;
