import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";
import { HOME, ABOUT, WORK } from "./constants";
import Homepage from "../pages/Homepage";
import Header from "../components/Header/Header";

const MainRoute = (props) => {
  return (
    <Switch>
      <Route path={HOME} component={Homepage} exact />
      <Route path={ABOUT} component={Header} exact />
      <Route path={WORK} component={Header} exact />
      <Redirect to={HOME} />
    </Switch>
  );
};

export default MainRoute;
