import React from "react";
import styles from "./Homepage.module.scss";
import Header from "../../components/Header/Header";
import { ReactComponent as WorkingSvg } from "../../assets/images/working.svg";

const Homepage = (props) => {
  return (
    <div className={styles.layout}>
      <Header {...props} />
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.myInfo}>
            <h2>Hi, I'm Avkash Chevli</h2>
            <p className={styles.subText}>
              I love to work as Frontend Engineer, and I believe in creating
              eye-catching, highly responsive and user friendly web
              applications.
            </p>
          </div>
          <div className={styles.profile}>
            <WorkingSvg className={styles.working} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
