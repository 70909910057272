import React, { useState, useEffect } from "react";
import { get } from "lodash";
import styles from "./Menu.module.scss";
import { ROUTES_LISTING } from "./constants";
import { Link } from "react-router-dom";

const Menu = (props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const pathname = get(props, "history.location.pathname", "");

  useEffect(() => {
    document.body.style.overflowY = isMenuOpen ? "hidden" : "auto";
  }, [isMenuOpen]);

  return (
    <div
      className={`${styles.container} ${
        isMenuOpen ? styles.menuOverlayActive : ""
      }`}
      onClick={() => {
        setMenuOpen(!isMenuOpen);
      }}
    >
      <span
        className={`${styles.hamburgerMenu} ${isMenuOpen ? styles.isOpen : ""}`}
        onClick={() => {
          setMenuOpen(!isMenuOpen);
        }}
      ></span>
      <div className={`${styles.menu} ${isMenuOpen ? styles.openMenu : ""}`}>
        <ul className={styles.menuList}>
          {ROUTES_LISTING.map((route, indx) => (
            <Link
              className={`${styles.menuItem} ${
                pathname === route.name ? `${styles.active}` : ""
              }`}
              key={`menu_${indx}`}
              to={route.name}
            >
              {route.label}
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
